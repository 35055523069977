<template>
  <div class="project-management-con">
    項目管理
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'

export default defineComponent({
  components: {

  },
  props: {

  },
  setup (props) {
    const state = reactive({

    })

    onMounted(async () => {

    })

    return {
      ...toRefs(state)
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .project-management-con {
    width: 100%;
    height: 100%;
    position: relative;
  }
</style>
